import React from 'react'
import PropTypes from 'prop-types'
import SanityImage from 'gatsby-plugin-sanity-image-alt'

import loadable from '@loadable/component'

import { useWindowSize } from '../helpers/useWindowSize'
import { useSanityImageShape } from '../helpers/useSanityImageShape'

const PortableText = loadable(() => import('./PortableText'))

const Profile = ({ person, full }) => {
  const { name, image, shortBio } = person
  const classes = !full && 'lg:col-span-8 lg:col-start-3'

  const windowSize = useWindowSize()

  const size = windowSize.width <= 768 ? 170 : 125

  const imageShaped = useSanityImageShape(image)

  return (
    <div className="grid grid-cols-12">
      <div className={`col-span-12 ${classes} md:flex md:items-start mb-12`}>
        <div className="mb-6 md:mb-0 md:flex-shrink-0 md:mr-8">
          <div style={{ width: size }}>
            {image && (
              <SanityImage
                {...imageShaped}
                className="rounded-full"
                alt={image.alt}
                width={size}
                height={size}
              />
            )}
          </div>
        </div>
        <div className="text-left">
          <p className="mt-0 font-bold">{name}</p>
          <PortableText blocks={shortBio} isBasic />
        </div>
      </div>
    </div>
  )
}

export default Profile

Profile.propTypes = {
  name: PropTypes.string,
  person: PropTypes.object,
  image: PropTypes.object,
  shortBio: PropTypes.object,
}
Profile.defaultProps = {}
